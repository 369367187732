import React from 'react';
import Button from 'components/Button';
import { externalLinkPattern } from 'shared/helpers/patterns';

const RedirectAction = ({
  callToAction: {
    style,
    buttonText,
    id,
    action: { url, openInANewTab },
  },
  prevAction,
  linkStyle,
  buttonStyle,
  extraStyle,
  isTextWithOrPrefix,
}) => {
  return openInANewTab || externalLinkPattern.test(url) ? (
    <a
      href={url}
      className={`${
        style?.includes('Button')
          ? `${buttonStyle} ${extraStyle}`
          : `${linkStyle} ${extraStyle}`
      } button`}
      target={openInANewTab ? '_blank' : '_self'}
      rel="noreferrer"
    >
      {prevAction?.includes('Button') && style === 'Text'
        ? `${isTextWithOrPrefix ? 'Or, ' : ''}${buttonText}`
        : buttonText}
    </a>
  ) : (
    <Button
      type={
        style?.includes('Button')
          ? `${buttonStyle} ${extraStyle}`
          : `${linkStyle} ${extraStyle}`
      }
      key={`heroSectionButton_${id}`}
      linkAsButton={true}
      path={url}
    >
      {prevAction?.includes('Button') && style === 'Text'
        ? `${isTextWithOrPrefix ? 'Or, ' : ''}${buttonText}`
        : buttonText}
    </Button>
  );
};

export default RedirectAction;
