import { gql } from '@apollo/client';

export const SEND_CONTACT_SPECIALIST_EMAIL = gql`
  mutation SendContactSpecialistEmail($fields: SpecialistEmailFields!) {
    ContactSpecialistEmail(fields: $fields) {
      code
      errorMessages
    }
  }
`;

export const SEND_REQUEST_CREDENTIALS_EMAIL = gql`
  mutation SendRequestCredentialsEmail(
    $fields: RequestCredentialsEmailFields!
  ) {
    RequestCredentialsEmail(fields: $fields) {
      code
      errorMessages
    }
  }
`;
