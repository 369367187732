import React, { useState } from 'react';
import { navigate } from 'gatsby';
import MODALS from 'src/utils/modalsConstants';
import Button from 'components/Button';
import ContactUsModal from 'components/ContactUsModal';
import { SEND_CONTACT_SPECIALIST_EMAIL } from 'services/graphql/queries/general-emails';
import Alert from 'components/Alert';
import { useMutation } from '@apollo/client';
const OpenFormAction = ({
  callToAction: {
    style,
    buttonText,
    id,
    action: { form },
  },
  prevAction,
  linkStyle,
  buttonStyle,
  extraStyle,
  isTextWithOrPrefix,
}) => {
  const [contactUsModal, setContactUsModal] = useState(false);
  const toggleContactUsModal = () => setContactUsModal(!contactUsModal);
  const [sendEmail, { error: emailError }] = useMutation(
    SEND_CONTACT_SPECIALIST_EMAIL
  );

  const pages = {
    [MODALS.ContactUs]: 'Contact Us',
    [MODALS.Consignment]: 'Consignment Info',
    [MODALS.CollectionsAndEstates]: 'Collections & Estates',
    [MODALS.PrivateSales]: 'Private Sales',
  };
  const page = pages[form];

  return (
    <>
      {emailError && <Alert color="danger" msg={emailError.message} />}
      <Button
        type={
          style?.includes('Button')
            ? `${buttonStyle} ${extraStyle}`
            : `${linkStyle} ${extraStyle}`
        }
        key={`heroSectionButton_${id}`}
        handleClick={toggleContactUsModal}
      >
        {prevAction?.includes('Button') && style === 'Text'
          ? `${isTextWithOrPrefix ? 'Or, ' : ''}${buttonText}`
          : buttonText}
      </Button>
      <ContactUsModal
        isOpen={contactUsModal}
        submit={async (fields) => {
          await sendEmail({
            variables: { fields: { ...fields, page: page } },
          });
          toggleContactUsModal();
          await navigate(window.location.pathname, {
            state: { emailSent: true },
          })
        }}
        toggle={toggleContactUsModal}
      />
    </>
  );
};

export default OpenFormAction;
