import React from 'react';
import SECTIONS from 'src/utils/sectionConstants';
import RedirectAction from 'components/CTA/RedirectAction';
import DownloadAction from 'components/CTA/DownloadAction';
import OpenFormAction from 'components/CTA/OpenFormAction';
import STYLES from 'src/utils/stylesConstants';

const CTA = ({ callToActions, extraStyle, isTextWithOrPrefix }) => {
  const buttonStyle = action => {
    switch (action?.style) {
      case STYLES.buttons.blackNoBg:
        return 'app-default-button';
      case STYLES.buttons.blueNoBg:
        return 'app-transparent-blue-button';
      case STYLES.buttons.whiteNoBg:
        return 'app-transparent-white-button';
      case STYLES.buttons.blueWhiteBg:
        return 'app-default-white-button no-border';
      case STYLES.buttons.whiteBlueBg:
        return 'app-primary-button no-border';
      default:
        return 'app-default-button';
    }
  };

  const linkStyle =
    'button app-white-link-button justify-content-start text-left mw-100';

  const renderAction = (action, index, prevAction) => {
    switch (action.action?.__typename) {
      case SECTIONS.RedirectAction:
        return (
          <RedirectAction
            key={`cta${index}`}
            buttonStyle={buttonStyle(action)}
            linkStyle={linkStyle}
            callToAction={action}
            prevAction={prevAction}
            extraStyle={extraStyle}
            isTextWithOrPrefix={isTextWithOrPrefix}
          />
        );
      case SECTIONS.DownloadAction:
        return (
          <DownloadAction
            key={`cta${index}`}
            buttonStyle={buttonStyle(action)}
            linkStyle={linkStyle}
            callToAction={action}
            prevAction={prevAction}
            extraStyle={extraStyle}
            isTextWithOrPrefix={isTextWithOrPrefix}
          />
        );
      case SECTIONS.OpenFormAction:
        return (
          <OpenFormAction
            key={`cta${index}`}
            buttonStyle={buttonStyle(action)}
            linkStyle={linkStyle}
            callToAction={action}
            prevAction={prevAction}
            extraStyle={extraStyle}
            isTextWithOrPrefix={isTextWithOrPrefix}
          />
        );
      default:
        return '';
    }
  };

  return (
    <>
      {callToActions?.length &&
        callToActions.map((action, index) =>
          renderAction(action, index, callToActions[index - 1]?.style)
        )}
    </>
  );
};

export default CTA;
