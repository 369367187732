import React from 'react';
import { Field } from 'formik';

const Textarea = ({ name, placeholder, showErrorMessages = true }) => (
  <Field name={name}>
    {({ field, meta }) => (
      <div className="app-input-text">
        <textarea
          className={`${meta.touched && meta.error ? 'error' : ''}`}
          name={field.name}
          placeholder={placeholder}
          value={field.value || ''}
          {...field}
        />
        {meta.touched && meta.error && showErrorMessages && (
          <p className="error-message">{meta.error}</p>
        )}
      </div>
    )}
  </Field>
);

export default Textarea;
