import React, { useState, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'reactstrap';
import Button from 'components/Button';
import { setTimeout } from 'timers';
import styles from './share.module.scss';

const tooltipDefaultValue = 'Copy to clipboard';
const tooltipCopiedValue = 'Copied to clipboard';

const Share = ({
  title = 'Share',
  icon = faSignOutAlt,
  stopPropagation = true,
  type = 'app-transparent-white-button',
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipText, setTooltipText] = useState(tooltipDefaultValue);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const copyText = () =>
    navigator.clipboard.writeText(window.location.href).then(() => {
      setTooltipText(tooltipCopiedValue);
      setTimeout(() => {
        toggleTooltip();
        setTooltipText(tooltipDefaultValue);
      }, 1000);
    });

  return (
    <Fragment>
      <Button
        id={title}
        type={type}
        handleClick={async e => {
          if (stopPropagation) {
            e.stopPropagation();
          }
          // navigator.share will work only on mobile
          if (navigator.share) {
            await navigator.share({
              title: 'GOODING & COMPANY',
              text: 'GOODING & COMPANY',
              url: window.location.href,
            });
          } else {
            copyText();
          }
        }}
      >
        <FontAwesomeIcon icon={icon} className={styles.shareIcon} />
        <span>{title}</span>
      </Button>
      <Tooltip
        placement="bottom"
        isOpen={tooltipOpen}
        target={title}
        toggle={toggleTooltip}
      >
        {tooltipText}
      </Tooltip>
    </Fragment>
  );
};

export default Share;
