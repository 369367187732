const sections = {
  CollectionOfLists: 'ContentfulWebCollectionOfParagraphsLists',
  ListOfHeroSections: 'ContentfulWebListOfHeroSections',
  RedirectAction: 'ContentfulWebRedirectAction',
  DownloadAction: 'ContentfulWebDownloadAction',
  OpenFormAction: 'ContentfulWebOpenFormAction',
  ParagraphWithHeadline: 'ContentfulWebParagraphWithHeadline',
  DynamicSections: {
    type: 'ContentfulWebDynamicSections',
    featuredVehicles: 'Featured Vehicles',
    verticalUpcomingAuctions: 'Vertical Upcoming Auctions',
    horizontalUpcomingAuctions: 'Horizontal Upcoming Auctions',
    horizontalUpcomingOnlineAuctions: 'Horizontal Upcoming Online Auctions',
    auctionResults: 'Auction Results',
    privateSales: 'Private Sales',
  },
  HeroImage: 'ContentfulWebHeroImage',
  SideBySide: 'ContentfulWebSideBySide',
  ListOfCards: 'ContentfulWebListOfCards',
  RichText: 'ContentfulWebRichTextSection',
  ListOfCta: 'ContentfulWebListOfCta',
  Card: 'ContentfulWebCard',
  webVideoMedia: 'webVideoMedia',
  webCard: 'webCard',
  webSectionWrapper: 'webSectionWrapper',
  ListOfContacts: 'ContentfulWebListOfContacts',
  ListOfVenues: 'ContentfulWebListOfVenues',
  VehicleCatalogOptions: 'ContentfulWebVehicleCatalogueOptions',
  Timeline: 'ContentfulWebTimeline',
  VideoMedia: 'ContentfulWebVideoMedia',
};

export default sections;
