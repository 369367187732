import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Form, Formik } from 'formik';
import Button from 'components/Button';
import {
  INITIAL_VALUES,
  VALIDATION_SCHEMA,
} from 'components/utils/contactUsModal';
import TextInput from 'components/form-elements/TextInput';
import TextArea from 'components/form-elements/TextArea';
import PhoneNumber from 'components/form-elements/PhoneNumber';
import CloseIcon from 'svg/CloseIcon';
import styles from './contactUsModal.module.scss';

const ContactUsModal = ({ isOpen, toggle, submit }) => (
  <Modal isOpen={isOpen} toggle={toggle} className={styles.contactUsModal}>
    <div className={styles.header}>
      <Button handleClick={toggle}>
        <CloseIcon color="silver" />
      </Button>
      <h3>Contact a Specialist</h3>
    </div>
    <ModalBody className={styles.body}>
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        validateOnMount
        onSubmit={values => {
          submit({
            name: values.name,
            message: values.message,
            phoneNumber: values.phoneNumber,
            email: values.email,
          });
        }}
      >
        {({ touched, isValid, isSubmitting }) => (
          <Form>
            <div className={styles.fieldGroup}>
              <div className={styles.field}>
                <TextInput
                  name="name"
                  placeholder="Name *"
                  showErrorMessages={false}
                />
              </div>

              <div className={styles.field}>
                <TextInput
                  name="email"
                  placeholder="Email*"
                  showErrorMessages={false}
                />
              </div>
            </div>
            <div className={styles.fieldGroup}>
              <div className={styles.field}>
                <TextInput
                  className={styles.field}
                  name="confirmEmail"
                  placeholder="Confirm Email*"
                  showErrorMessages={false}
                />
              </div>
              <div className={styles.field}>
                <PhoneNumber
                  name="phoneNumber"
                  placeholder="Phone Number*"
                  showErrorMessages={false}
                  label="&#42;"
                />
              </div>
            </div>
            <div className={styles.fieldGroup}>
              <div className={styles.textField}>
                <TextArea
                  name="message"
                  placeholder="Message of Inquiry*"
                  showErrorMessages={false}
                />
              </div>
            </div>
            <div className={styles.footer}>
              <Button
                btnType="submit"
                type="app-primary-button"
                disabled={!!!Object.keys(touched).length || !isValid || isSubmitting}
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalBody>
  </Modal>
);

export default ContactUsModal;
