import MediaBanner from 'static/images/media-banner.png';
import { SOCIAL_MEDIA_LINKS } from 'utils/socialMediaConstants';

const ITEMS = [
  {
    title: 'LOT 001',
    src:
      'https://images.ctfassets.net/moe5wzpj72u9/1txEfCk3i3ClVh1M12WjEG/ca43a9f3653c56f2516edd9e8fa060fa/1934_Bugatti_Type_59_Sports-04.jpg?w=2700&h=1800&q=90&fm=webp',
  },
  {
    title: 'LOT 002',
    src:
      'https://images.ctfassets.net/moe5wzpj72u9/fgWanJg7KBWxy5Cwj8QWi/9b7dc448ba4fba3ba035311d2b43b1da/1961_Aston_Martin_DB4_GT_Zagato-09.jpg?w=2700&h=1800&q=90&fm=webp',
  },
  {
    title: 'LOT 003',
    src:
      'https://images.ctfassets.net/moe5wzpj72u9/1txEfCk3i3ClVh1M12WjEG/ca43a9f3653c56f2516edd9e8fa060fa/1934_Bugatti_Type_59_Sports-04.jpg?w=2700&h=1800&q=90&fm=webp',
  },
];

const NEWS = [
  {
    title: `A Selection of Rare Supercars are Announced for Amelia
    Island as Gooding & Company’s Online Catalogue Goes Live`,
    description: `Santa Monica, Calif. (February 13, 2020) – Gooding &
    Company, the auction house revered for selling the world’s
    most desirable and prized collector cars, is pleased to
    reveal an outstanding array of low-mileage`,
    image: MediaBanner,
  },
];

const PRESS = [
  {
    title: `A Selection of Rare Supercars are Announced for Amelia
      Island as Gooding & Company’s Online Catalogue Goes Live`,
    description: `Santa Monica, Calif. (February 13, 2020) – Gooding &
      Company, the auction house revered for selling the world’s
      most desirable and prized collector cars, is pleased to
      reveal an outstanding array of low-mileage`,
    image: MediaBanner,
  },
  {
    title: `A Selection of Rare Supercars are Announced for Amelia
      Island as Gooding & Company’s Online Catalogue Goes Live`,
    description: `Santa Monica, Calif. (February 13, 2020) – Gooding &
      Company, the auction house revered for selling the world’s
      most desirable and prized collector cars, is pleased to
      reveal an outstanding array of low-mileage`,
    image: MediaBanner,
  },
];

const SOCIAL_LINKS = [
  {
    title: 'Facebook',
    value: 'Gooding & Company',
    link: SOCIAL_MEDIA_LINKS.facebook,
  },
  {
    title: 'Twitter',
    value: 'Gooding & Company',
    link: SOCIAL_MEDIA_LINKS.twitter,
  },
  {
    title: 'Instagram',
    value: 'Gooding & Company',
    link: SOCIAL_MEDIA_LINKS.instagram,
  },
  {
    title: 'YouTube',
    value: 'Gooding & Company',
    link: SOCIAL_MEDIA_LINKS.youtube,
  },
];

const DUMMY_TEXT = `In 2018, Gooding & Company achieved more than $201 million in total
sales for 316 lots sold and posted a strong 87% sell-through rate at
its three annual sales, held in Scottsdale, Arizona; Amelia Island,
Florida; and Pebble Beach, California, where Gooding & Company is
the official auction house of the Pebble Beach Concours d’Elegance®.
In 2019, the company achieved an incredible milestone of over $2
billion since its inception in 2004.`;

export { NEWS, PRESS, ITEMS, SOCIAL_LINKS, DUMMY_TEXT };
